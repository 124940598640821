import React, { Fragment } from "react"
import Layout from "../components/layout/layout"
import HeaderTagline from "../components/shared/headerTagling"
import Hero from "../components/shared/hero"
import SectionHeader from "../components/shared/sectionHeader"
import Underline from "../components/shared/underline"
import Wrapper from "../components/shared/wrapper"
import { FaGem } from "react-icons/fa"
import ProjectBox from "../components/shared/projectBox"
// Images
import { getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { convertToBgImage } from "gbimage-bridge"
// Services
import { getWorkPageData } from "../services/work"
// Interfaces
import { IWorkPage } from "../interfaces/workPage"
import SEO from "../components/shared/seo"
const Work = () => {

  const data: IWorkPage = getWorkPageData()

  const img = getImage(data.hero.backgroundImage)
  const bgImg = convertToBgImage(img)
  return (
    <Fragment>
      <SEO title={data.title} description={data.description ? data.description : data.title} />
      <Layout>
        {/* Hero */}
        <div>
          <Hero
            bgImg={bgImg}
            header={data.hero.header}
            tagline={data.hero.tagline}
          />
        </div>
        <div className="bg-green">
          <Wrapper mx={true} py={true}>
            <div className="flex justify-center items-center flex-col">
              <SectionHeader text={data.workSection.header} />
              <HeaderTagline
                classsName="text-white"
                text={data.workSection.description.description}
              />
              <Underline />
              <div className="mt-10">
                <FaGem className="text-7xl  text-white" />
              </div>
            </div>
          </Wrapper>
          <div className="flex flex-wrap mt-10">
            {data.projects.map((pro) => (
              <ProjectBox url={pro.slug} text={pro.header} image={pro.image.gatsbyImageData} />
            ))}

          </div>
        </div>
      </Layout>
    </Fragment>
  )
}

export default Work
