import React, { Fragment, FC } from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import BackgroundImage, { IBackgroundImageProps } from "gatsby-background-image"

const HeroWrapper = styled.section`
  min-height: 700px;
  ${tw``};
`
const ContentWrapper = styled.section`
  min-height: 700px;
  ${tw`flex justify-center items-center`};
`

interface IProps {
  header: string
  tagline: string
  bgImg: Partial<IBackgroundImageProps | IBackgroundImageProps[] | null>
}
const Hero: FC<IProps> = ({ header, tagline, bgImg }) => {
  return (
    <Fragment>
      <HeroWrapper>
        <BackgroundImage
          className="w-full h-full bg-center bg-fixed"
          {...bgImg}
        >
          <ContentWrapper>
            <div className="flex justify-center items-center flex-col text-center h-auto">
              <h2 className="text-white lg:text-8xl sm:text-6xl text-5xl ">
                {header}
              </h2>
              <h5 className="text-DarkWhite mt-5 lg:text-xl text-lg  ">
                {tagline}
              </h5>
            </div>
          </ContentWrapper>
        </BackgroundImage>
      </HeroWrapper>
    </Fragment>
  )
}

export default Hero
