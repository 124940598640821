import { graphql, useStaticQuery } from "gatsby"


export const getWorkPageData = () => {
    const { workPageData } = useStaticQuery(query)
    return workPageData
}

const query = graphql`
{
    workPageData: contentfulWorkPage {
      title
      hero {
        header
        backgroundImage {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
        tagline
      }
      workSection {
        header
        description {
          description
        }
      }
      projects {
        header
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  }
  
  
`