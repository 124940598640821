import React, { FC, Fragment } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image"
const ImageWrapper = styled(motion.div)`
  ${tw` relative flex justify-center cursor-pointer `};
`
const ImageLayout = styled(motion.div)`
  ${tw`absolute h-full bg-primary z-10 overflow-hidden  opacity-40 flex justify-center items-center cursor-pointer`};
`

const LayoutMotion = {
  rest: {
    opacity: 0,
    width: "0px",
    ease: "easeOut",
    duration: 0.4,
    type: "tween",
  },
  hover: {
    width: "100%",
    opacity: "90%",
    transition: {
      duration: 0.4,
      type: "tween",
      ease: "easeIn",
    },
  },
}

interface IProps {
  url: string
  text: string
  image: IGatsbyImageData
}
const ProjectBox: FC<IProps> = ({ url, text, image }) => {

  // const img = getImage(image)

  return (
    <Fragment>
      {/* <Link to={url} className="w-6/12"> */}
      <span className="w-6/12">
        <ImageWrapper initial="rest" whileHover="hover" animate="rest">
          <ImageLayout variants={LayoutMotion}>
            <p className="sm:text-xl md:text-3xl text-lg font-normal uppercase text-white whitespace-nowrap">
              {text}
            </p>
          </ImageLayout>

          <GatsbyImage image={image} alt={text} />
        </ImageWrapper>
      </span>
      {/* </Link> */}
    </Fragment>
  )
}

export default ProjectBox
